import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import get from 'lodash/get'
import Header from '../components/Header/header'
import {web_design,video_advertisment,online_store, advertising, graphic_design, all_works, categories, previous_page, next_page, contact_us} from '../components/strings'
import AOS from 'aos';
import Helmet from 'react-helmet'

 class AdvertisingPage extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const { currentPage, numPages_advertising } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages_advertising
    const prevPage = currentPage - 1 === 1 ? "/portfolio/digital-marketing/" : ("/portfolio/digital-marketing/" + (currentPage - 1).toString())
    const nextPage = "/portfolio/digital-marketing/" + (currentPage + 1).toString()
    const prefix_string = "en";

    return (
      <Layout>
        <Helmet
          title="WELL - PORTFOLIO | Digital Marketing"
          meta={[
            { name: 'description', content: 'In our portfolio you will find many demanding websites, online stores & applications, but also digital marketing and graphic design projects.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
            ]} >
        </Helmet>
         <div id="portfolio">
          <div data-aos='fade-zoom-in'>
            <Header prefix_string_page={prefix_string}/>
            <section id="p_portfolio" className="hero is-fullheight pages">
            <div className="hero-head"></div>
            <div className="hero-body is-padding-0">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h1 className="title">{advertising}</h1>
                <div className="columns">
                    <div className="column is-3 fixed">
                        <ul className="p_navigation">
                            <div data-aos='fade-zoom-in' className="back">
                                <h6 className="title">{categories}</h6>
                               {/* <li><Link to="/portfolio"><h2>{all_works}</h2></Link></li> */}
                                <li><Link to="/portfolio/web-design"><h2>{web_design}</h2></Link></li>
                                <li><Link to="/portfolio/online-store"><h2>{online_store}</h2></Link></li>
                                <li><Link to="/portfolio/graphic-design"><h2>{graphic_design}</h2></Link></li>
                                <li className="selected"><Link to="/portfolio/digital-marketing"><h2>{advertising}</h2></Link></li>
                                <li><Link to="/portfolio/video-advertisment"><h2>{video_advertisment}</h2></Link></li>
                            </div>
                        </ul>
                            <div className="contact-info ci-portfolio">
                                <a href="tel:+38761787346" className="line"><h4>+387 61 787 346</h4></a>
                                <a href="mailto:info@wellpromotion.ba" className="line email"><h4>info@wellpromotion.ba</h4></a>
                                <h4 className="line">Safeta Mujića 2, Sarajevo, 71000</h4>
                                <Link to="/contact" className="button gold">{contact_us}<span className="icon contact"></span></Link>
                            </div>
                    </div>

                    <div className="column is-9 is-right">
                   
                    <div className="panel columns is-multiline">
                    
                        { 
                        posts.map(({ node }) => {
                          return( 
                            <Link
                            to={node.fields.slug}
                            className="column is-4 single-product">
                         
                          <div className="hover_overlay"></div>
                          <div className="product-image"><img src={node.frontmatter.image} alt={node.frontmatter.meta} title={node.frontmatter.meta} /></div>
                          {node.frontmatter.contentType}
                          <div className="product-info">
                              <div className="left">
                                  <h3 className="category">{node.frontmatter.category}</h3>
                                  <h4 className="product-title">{node.frontmatter.title}</h4>
                              </div>
                              <div className="right">
                                  <span className="read-more"></span>
                              </div>
                          </div>  
                        
                        </Link>
                        )  })}                    
                    </div>
                        <div className="contact-info ci-portfolio mobile">
                        
                                <a href="tel:+38761787346" className="line"><h4>+387 61 787 346</h4></a>
                                <a href="mailto:info@wellpromotion.ba" className="line email"><h4>info@wellpromotion.ba</h4></a>
                                <h4 className="line">Safeta Mujića 2, Sarajevo, 71000</h4>
                                <Link to="/contact" className="button gold">{contact_us}<span className="icon contact"></span></Link>
                        </div>
                    </div>
                </div>
                <ul className="pagination">
                { !isFirst && <Link to={prevPage} rel="prev">← {previous_page}</Link> }
                { Array.from({ length: numPages_advertising }, (_, i) => (
                    <li key={`pagination-number${i + 1}`} >
                      <Link to={`/portfolio/digital-marketing/${i === 0 ? '' : i + 1}`} style={{
                          color: i + 1 === currentPage ? '#ffffff' : '', 
                          background: i + 1 === currentPage ? '#D4AC69' : ''
                        }} >
                        {i + 1}
                      </Link>
                    </li>
                  ))
                }
                { !isLast && <Link to={nextPage} rel="next">{next_page} &nbsp;→</Link> }
                
            </ul>
            </div>
        </div>
    <div className="hero-foot">
   
    </div>
</section>
        </div>
        
     </div>
     
      </Layout>
    )
  }
}

AdvertisingPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default AdvertisingPage

export const advertisingPageQuery = graphql`

  query advertisingPageQuery($skip: Int = 0, $limit: Int = 6) {
    allMarkdownRemark(sort: { fields: [frontmatter___sorting_date], order: DESC },
        filter: { frontmatter: { templateKey: { eq: "en_portfolio" }, category: { eq: "Digital Marketing" } } },
         limit: $limit, skip: $skip) {
        edges {
          node {
            excerpt
            id
            fields {
              slug
            }
            frontmatter {
                title
                client
                category
                date
                sorting_date
                website
                website_url
                description
                meta
                image
            }
          }
        }
      }
  }
`
